export const environment = {
  production: true,
  secret: 't;JhMf^&XbG7]HN%x/CBFJ@]BBHedp',
};

export const apiConfig = {
  baseUrl: 'https://stage-api.bunkai.io/', // TODO: use process.env to populate this value at build time
  DEFAULT_CONTENT_TYPE: 'multipart/form-data',
  DEFAULT_RESPONSE_TYPE: 'json',
  WITH_CREDENTIALS: false,
  IS_APP: true,
};

export  const versionNumber = 'stage';
export  const deployNumber = '08007d5e';

export const supportedLanguages = [
  //{ text: 'Español', value: 'es', img: '' },
  { text: 'English', value: 'en', img: '' },
];

export  const stripeApiKey = 'pk_test_51KgPMBINrWb6Gg6abPVWbEeQoWP30MZY1rIxHtUpq1i7ESnGqPrA30Ge8OEHzNQh1C4EIgImS4rR2qlwQna0BWlR004bVROgpq';

export const termsAndConditionsUrl = 'https://lp.bunkai.io/terms-and-conditions';
export const privacyPolicysUrl = 'https://lp.bunkai.io/legal-privacy-policy';

export const FirebaseConfigDef = {
  apiKey: 'AIzaSyALkZDkNG1063yhjLC3gs1tN1B4FARXazI',
  authDomain: 'jfriastest.firebaseapp.com',
  projectId: 'jfriastest',
  storageBucket: 'jfriastest.appspot.com',
  messagingSenderId: '899240444932',
  appId: '1:899240444932:web:79159815e7471e7c712151',
  measurementId: 'G-6XWDF2CGCM',
};

export const defaultLanguage = 'en';

export const userStateRouting = true;

export const ConectivityTypes = {
  SIM: 'SIM',
  LOC: 'LOC',
};

export const ConectivityStatus = {
  true: true, //active
  false: false, //inactive
};

export const TableReferences = {
  b_sim_cards: 'SIM_CARD',
  b_vpn_devices: 'VPN_DEVICE',
  b_vpn_router: 'VPN_ROUTER',
};

export const ScanTypesDef = {
  trafficAnalysisOnly: 1,
  mobileAppOnly: 2,
  firmwareOnly: 3,
  trafficMobileApp: 4,
  trafficMobileAppFirmware: 5,
  mobileAppFirmware: 6,
};

export const ProjectTypesDef = {
  ioTDeviceOnly: 1,
  appOnly: 2,
  firmwareOnly: 3,
  ioTDeviceApp: 4,
  ioTDeviceAppFirmware: 5,
};

export const DeviceConnectionStatusDef = {
  found: 'found',
  notstarted: 'notstarted',
  failure: 'failure',
};

export const SelectableIconsPreset = [
  '/assets/selectable_icons/air-conditioner.jpg',
  '/assets/selectable_icons/camera.jpg',
  '/assets/selectable_icons/cctv.jpg',
  '/assets/selectable_icons/chip.jpg',
  '/assets/selectable_icons/cloud-server-1.jpg',
  '/assets/selectable_icons/cloud-server.jpg',
  '/assets/selectable_icons/gear.jpg',
  '/assets/selectable_icons/globe.jpg',
  '/assets/selectable_icons/headphones.jpg',
  '/assets/selectable_icons/hifi.jpg',
  '/assets/selectable_icons/internet-1.jpg',
  '/assets/selectable_icons/internet.jpg',
  '/assets/selectable_icons/laptop.jpg',
  '/assets/selectable_icons/microphone.jpg',
  '/assets/selectable_icons/modem.jpg',
  '/assets/selectable_icons/monitor.jpg',
  '/assets/selectable_icons/movie-projector.jpg',
  '/assets/selectable_icons/parabolic-dishes.jpg',
  '/assets/selectable_icons/pen-tablet.jpg',
  '/assets/selectable_icons/place.jpg',
  '/assets/selectable_icons/printer.jpg',
  '/assets/selectable_icons/remote-control.jpg',
  '/assets/selectable_icons/robot-vacuum-cleaner.jpg',
  '/assets/selectable_icons/shield.jpg',
  '/assets/selectable_icons/smart-bulb.jpg',
  '/assets/selectable_icons/smart-door.jpg',
  '/assets/selectable_icons/smart-home-1.jpg',
  '/assets/selectable_icons/smart-home-2.jpg',
  '/assets/selectable_icons/smart-home-3.jpg',
  '/assets/selectable_icons/smart-home-4.jpg',
  '/assets/selectable_icons/smart-home.jpg',
  '/assets/selectable_icons/smart-key-1.jpg',
  '/assets/selectable_icons/smart-key.jpg',
  '/assets/selectable_icons/smart-lock.jpg',
  '/assets/selectable_icons/smart-refrigerator.jpg',
  '/assets/selectable_icons/smart-temperature.jpg',
  '/assets/selectable_icons/smart-tv.jpg',
  '/assets/selectable_icons/smart-washing-machine.jpg',
  '/assets/selectable_icons/smartphone-1.jpg',
  '/assets/selectable_icons/smartphone.jpg',
  '/assets/selectable_icons/smartwatch.jpg',
  '/assets/selectable_icons/uninterrupted-power-supply.jpg',
];
