/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AddOrderDetails { 
    apartment?: string;
    city: string;
    companyName?: string;
    country: string;
    deliveryMethod: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    postalCode: string;
    state: string;
    streetAddress: string;
}

